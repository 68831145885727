@import "../../config/variables";

.accordion-h {
  $this: &;

  &__item {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.js-enabled {

      #{$this}__content {
        visibility: hidden;
      }
    }

    &.is-active {

      #{$this}__inner {
        border-color: #D9E1EB;
      }

      #{$this}__title {

        &::after {
          transform: rotate(180deg);
        }
      }

      #{$this}__content {
        padding-top: 14px;
        padding-bottom: 20px;
        visibility: visible;
      }
    }
  }

  &__inner {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ECF0F5;
    box-shadow: 0 0 50px rgba(0, 0, 0, .05);
    transition: .3s ease;
  }

  &__title {
    position: relative;
    font-size: 17px;
    font-weight: 300;
    padding: 14px 50px 14px 20px;
    margin: 0;
    cursor: pointer;
    transition: .2s ease;

    &::after {
      content: '';
      position: absolute;
      top: 25px;
      right: 20px;
      transform: translateY(-50%);
      width: 12px;
      height: 8px;
      background: url(/res/images/icons/ico-arrow-down.svg) no-repeat center;
      transition: .3s ease;
    }
  }

  &__content {
    padding: 0 50px 0 20px;
    transition-property: all;
    transition-timing-function: linear;
    overflow: hidden;

    a {
      color: $color-accent-sec-dark;
      text-decoration: underline;

      &:hover, &:focus-visible {
        text-decoration: none;
      }

      &:focus-visible {
        outline: 2px solid $color-accent-sec-dark;
      }
    }

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
