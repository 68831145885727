$font-size-main: 17px;

$color-main: #373737;
$color-secondary: #fff;
$color-accent: #FD7E2E;
$color-accent-light: #FA8500;
$color-accent-sec: #0D79FA;
$color-accent-sec-dark: #006FF5;
$color-accent-sec-light: #66AAFC;
$color-accent-2: #20B967;
$color-border: #D9E1EB;
$color-error: #ff5252;
$color-light: #476E9A;
$color-dark: #000;

// breakpoints
$xlg: 1399px;
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 575px;
$xxs: 480px;
