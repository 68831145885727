.accordion-h__item {
  margin-bottom: 30px;
}
.accordion-h__item:last-of-type {
  margin-bottom: 0;
}
.accordion-h__item.js-enabled .accordion-h__content {
  visibility: hidden;
}
.accordion-h__item.is-active .accordion-h__inner {
  border-color: #D9E1EB;
}
.accordion-h__item.is-active .accordion-h__title::after {
  transform: rotate(180deg);
}
.accordion-h__item.is-active .accordion-h__content {
  padding-top: 14px;
  padding-bottom: 20px;
  visibility: visible;
}
.accordion-h__inner {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ECF0F5;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  transition: 0.3s ease;
}
.accordion-h__title {
  position: relative;
  font-size: 17px;
  font-weight: 300;
  padding: 14px 50px 14px 20px;
  margin: 0;
  cursor: pointer;
  transition: 0.2s ease;
}
.accordion-h__title::after {
  content: "";
  position: absolute;
  top: 25px;
  right: 20px;
  transform: translateY(-50%);
  width: 12px;
  height: 8px;
  background: url(/res/images/icons/ico-arrow-down.svg) no-repeat center;
  transition: 0.3s ease;
}
.accordion-h__content {
  padding: 0 50px 0 20px;
  transition-property: all;
  transition-timing-function: linear;
  overflow: hidden;
}
.accordion-h__content a {
  color: #006FF5;
  text-decoration: underline;
}
.accordion-h__content a:hover, .accordion-h__content a:focus-visible {
  text-decoration: none;
}
.accordion-h__content a:focus-visible {
  outline: 2px solid #006FF5;
}
.accordion-h__content p {
  margin-bottom: 20px;
}
.accordion-h__content p:last-child {
  margin-bottom: 0;
}